import React, {Component} from "react";
import {useState, useEffect} from 'react'
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import WarningIcon from '@mui/icons-material//Warning';
import SongDeleted from '../Dialogs/SongDeleted.js';
import useFetch from '../APIs/DeleteSong.js';
export default function BasicModal(props) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        props.setIsDeletingSong(false)
    
    }


    useEffect(()=>{
        handleOpen()
    },[])


    const [data, setData] = useState(null);
    const [request,setRequest] = useState(null)
    const[isDialogOpened, setisDialogOpened] = useState(false)
  
    const {data:FetchedData,isLoading, error} = useFetch(request)
  
  
    useEffect(()=>{
      if(FetchedData){
          setData(FetchedData)
          setisDialogOpened(true)
      }
    },[FetchedData])
  
    const onhandle_submit= (e) =>{
      e.preventDefault()
      setRequest({
        "song_id": props.song_id
      })
      
    }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
            <div className="myModal fadefx">
                <div className="container">
                    <div className="errIcon">
                        <WarningIcon/>
                    </div>
                        <form className="myform" onSubmit={onhandle_submit}>
                        <div className="errbody">
                            <p><b>Delete Song</b><br/><br/>
                            {props.song_title}<br/><br/>
                            Remember we will not be able to recover your information.</p>
                            {error &&(
                              <div className="myerr">  
                                  {error==="not_logged_in" && (<p>You are not logged in </p>)}
                                  {error==="song_not_specified" && (<p>Song not specified, please report to developers@geniux.co.za </p>)}
                                  {error==="song_not_found" && (<p>Song not found or has been removed </p>)}
                                  {error==="song_not_specified" && (<p>Something went wrong, please report to developers@geniux.co.za </p>)}
                                  {error==="network_err" && (<p>Check your internet connectivity </p>)}
                              </div>  
                            )}
                              
                            <Button className="btn" type="submit">YES, DELETE {(isLoading) && <CircularProgress/>}</Button>
                            <Button onClick={()=>handleClose()} className="btn closebtn">Close</Button>
                        </div>
                  
                    
                    
                    </form>
                </div>
            </div>
      </Modal>
    {(data && isDialogOpened) &&(
        <SongDeleted myuserid={props.myuserid} setisDialogOpened={setisDialogOpened} setIsDeletingSong ={props.setIsDeletingSong }/>
    )}
    </>
  );
}

